.mat-dialog-container{
    background-color: $color-white;
    color: $color-black;
    position: relative;
    padding: 40px !important;
    border-radius: $radius !important;
    width: 850px !important;
    @include max-screen($tablet) {
        padding: 20px !important;
        width: 100% !important;
    }
    .modal {
        header {
             margin-bottom: 15px;
            @include max-screen($tablet){
                margin-bottom: 15px;
            }
            &.gray{
                background-color: $color-lightblue; 
                padding: 25px 40px;
                margin:-40px -40px 20px;
                position: sticky;
                top: -40px;
                @include max-screen($tablet) {
                    padding: 20px;
                    margin:-20px -20px 20px;
                    top: -20px;
                }
            }
            h2 {
                font-size: 28px;
                @include max-screen($tablet) {
                  font-size: 20px;
                }
              }
        }
      
        &__buttonCol {
            margin: 25px -15px 0;
                  app-button {
                      width: 50%;
                      display: block;
                      padding: 0 15px;
                      @include max-screen($smobile) {
                        width:100%;
                        margin-bottom: 15px;
                    }
                  }
        }
        &__button {
            margin: 25px 0 0;
                  app-button {
                      width: 100%;
                      display: block;
                  }
        }
        &__close{
            background-color: $color-lightblue;
            @include round-image(38px);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
            cursor: pointer;
            @include max-screen($mobile) {
                @include round-image(20px);
            }
            
            &:hover{
                background-color: lighten($color-lightblue, 0.9%);
            }
        }
        &--center{
            text-align: center;
        }
      }
}
