.contentpage {
    padding: 40px 0;
    @include max-screen($tablet) {
        padding: 20px 0;
    }
    h1 {
        color: $color-black;
        margin-bottom: 20px;
        font-size: 40px;
        @include max-screen($tablet) {
            font-size: 25px;
            margin-bottom: 10px;
        }
    }
    h3 {
        font-size: 20px;
        margin-bottom: 15px;
        color: $color-black;
        @include max-screen($tablet) {
            font-size: 16px;
            margin-bottom: 10px;
        }
    }
    p {
        color: $color-darkgrey;
        font-size: 18px;
        line-height: 1.6;
        margin-bottom: 20px;
        @include max-screen($tablet) {
            font-size: 14px;
            line-height: 1.5;
            margin-bottom: 15px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    a {
        color: $color-primarydark;
        &:hover {
            text-decoration: underline;
        }
    }
}

// signup-form

.mainRow {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    &__col {
        width: calc(50% - 15px);
        margin-right: 15px;
        @include max-screen($mobile) {
            width: 100%;
            margin: 0;
        }
        &:last-child {
            margin-left: 15px;
            margin-right: 0;
            @include max-screen($mobile) {
                width: 100%;
                margin: 0;
            }
        }
    }
}

// order-form
.order__form{
    &-row{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
        @include max-screen($mobile){
            margin: 0;
        }
    }
    &-col{
        width: 50%;
        padding: 0 10px;
        margin-top: 15px;
        @include max-screen($mobile){
            width: 100%;
            padding: 0;
        }
    }
    &-half{
        width: 25%;
        padding: 0 10px;
        margin-top: 15px;
        @include max-screen($desktop-large) {
            width: 50%;
        }
        @include max-screen($mobile){
            width: 100%;
            padding: 0;
        }
    }
}