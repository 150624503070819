@font-face {
    font-family: "GT Walsheim";
    src: url("../fonts/GTWalsheim-Rg.eot");
    src: url("../fonts/GTWalsheim-Rg.eot?#iefix") format("embedded-opentype"),
        url("../fonts/GTWalsheim-Rg.woff2") format("woff2"), url("../fonts/GTWalsheim-Rg.woff") format("woff"),
        url("../fonts/GTWalsheim-Rg.ttf") format("truetype"),
        url("../fonts/GTWalsheim-Rg.svg#GTWalsheim-Rg") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GT Walsheim";
    src: url("../fonts/GTWalsheim-RgOb.eot");
    src: url("../fonts/GTWalsheim-RgOb.eot?#iefix") format("embedded-opentype"),
        url("../fonts/GTWalsheim-RgOb.woff2") format("woff2"), url("../fonts/GTWalsheim-RgOb.woff") format("woff"),
        url("../fonts/GTWalsheim-RgOb.ttf") format("truetype"),
        url("../fonts/GTWalsheim-RgOb.svg#GTWalsheim-RgOb") format("svg");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "GT Walsheim";
    src: url("../fonts/GTWalsheim-Bold.eot");
    src: url("../fonts/GTWalsheim-Bold.eot?#iefix") format("embedded-opentype"),
        url("../fonts/GTWalsheim-Bold.woff2") format("woff2"), url("../fonts/GTWalsheim-Bold.woff") format("woff"),
        url("../fonts/GTWalsheim-Bold.ttf") format("truetype"),
        url("../fonts/GTWalsheim-Bold.svg#GTWalsheim-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GT Walsheim";
    src: url("../fonts/GTWalsheim-BoldOblique.eot");
    src: url("../fonts/GTWalsheim-BoldOblique.eot?#iefix") format("embedded-opentype"),
        url("../fonts/GTWalsheim-BoldOblique.woff2") format("woff2"),
        url("../fonts/GTWalsheim-BoldOblique.woff") format("woff"),
        url("../fonts/GTWalsheim-BoldOblique.ttf") format("truetype"),
        url("../fonts/GTWalsheim-BoldOblique.svg#GTWalsheim-BoldOblique") format("svg");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "GT Walsheim";
    src: url("../fonts/GTWalsheim-Light.eot");
    src: url("../fonts/GTWalsheim-Light.eot?#iefix") format("embedded-opentype"),
        url("../fonts/GTWalsheim-Light.woff2") format("woff2"), url("../fonts/GTWalsheim-Light.woff") format("woff"),
        url("../fonts/GTWalsheim-Light.ttf") format("truetype"),
        url("../fonts/GTWalsheim-Light.svg#GTWalsheim-Light") format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GT Walsheim";
    src: url("../fonts/GTWalsheim-LightOblique.eot");
    src: url("../fonts/GTWalsheim-LightOblique.eot?#iefix") format("embedded-opentype"),
        url("../fonts/GTWalsheim-LightOblique.woff2") format("woff2"),
        url("../fonts/GTWalsheim-LightOblique.woff") format("woff"),
        url("../fonts/GTWalsheim-LightOblique.ttf") format("truetype"),
        url("../fonts/GTWalsheim-LightOblique.svg#GTWalsheim-LightOblique") format("svg");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}
