.auth {
    min-height: 100vh;
    position: relative;
    background-color: $color-primary;
    @include max-screen($stablet) {
        flex-direction: column-reverse;
        justify-content: flex-end;
    }
    &__left,
    &__right {
        width: 50%;
        @include max-screen($stablet) {
            width: 100%;
        }
    }
    &__left {
        padding: 60px 20px;
        background-color: $color-primary;
        @include max-screen($stablet) {
            padding: 30px 20px;
            flex: 1;
        }
        @include max-screen($mobile){
            padding: 30px 20px 60px;
        }
        &-inner{
            max-width: 500px;
            margin: 0 auto;
            width: 100%;
        }
        &-logo {
            width: 100%;
            max-width: 500px;
            margin: 0 auto 40px;
            @include max-screen($stablet) {
                position: absolute;
                top: 20px;
                left: 0px;
                padding-left: 20px;
                max-width: 100%;
            }
        }
        &-content {
            flex: 1;
        }
    }
    &__right {
        @include background-image(clock, jpg, center center, no-repeat, cover);
        @include max-screen($stablet) {
            min-height: 300px;
            flex: 1;
        }
    }
}
.authform {
    color: $color-white;
    width: 100%;
    h3 {
        font-size: 35px;
        margin-bottom: 28px;
        font-weight: 600;
        @include max-screen($stablet) {
            font-size: 20px;
            margin-bottom: 10px;
        }
    }
    &__field {
        margin-top: 25px;
    }
    &__text {
        text-align: right;
        padding: 10px 0 0;
        a {
            font-size: 16px;
            color: $color-secondary;
            &:hover {
                color: $color-secondarydark;
            }
        }
    }
    &__btn {
        padding: 30px 0;
        @include max-screen($stablet) {
            padding: 20px 0;
        }
    }
    &__bottom {
        text-align: center;
        a {
            color: $color-white;
            &:hover {
                color: $color-secondarydark;
            }
        }
    }
}
app-login,
app-sign-up,
app-recover-password,
app-new-password {
    width: 100%;
}
