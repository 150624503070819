* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    color: $color-black;
    font-size: $font-size-base;
    font-family: $font-family-base;
    line-height: $line-height-base;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}
a {
    outline: none;
    cursor: pointer;
    text-decoration: none;
}
img {
    height: auto;
    max-width: 100%;
}
:focus {
    outline: none;
}
::-ms-expand {
    display: none;
}
ul,
ol {
    list-style: none;
}
.wrapper {
    max-width: $max-width;
    padding-left: $gutter-side;
    padding-right: $gutter-side;
    margin: 0 auto;
    width: 100%;
    @include max-screen($desktop-large) {
        max-width: 1199px;
    }
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba($color-primarydark, 0.2);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba($color-primarydark, 0.8);
    border-radius: $radius;
}

.zopim{
    z-index: 9 !important;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}