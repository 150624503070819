/**mat font override**/
.mat-expansion-panel-header,
.mat-expansion-panel-content,
.mat-form-field,
.mat-checkbox,
.mat-snack-bar-container,
textarea {
    font-family: $font-family-base !important;
}
/**Snackbar design override**/
.mat-snack-bar-container {
    padding: 0 !important;
    min-height: 0 !important;
    background-color: transparent !important;
    margin: 0 auto 20px !important;
    @include max-screen($mobile) {
        margin: 20px auto !important;
    }
}

/**matt field override**/

.mat-form-field {
    width: 100%;
    .mat-form-field-wrapper {
        padding: 0 !important;
        width: 100%;
        .mat-form-field-flex {
            align-items: center;
        }
        .mat-form-field-infix {
            margin: 0;
            padding: 0;
            border: 0;
            input {
                font-size: 15px;
                line-height: 24px;
                border-radius: 4px;
                padding: 14px 20px;
                box-sizing: border-box;
                border: 1px solid $color-black;
                color: $color-black;
                font-weight: 400;
                caret-color: $color-black !important;
                margin-top: 0;
                .auth &{
                    border: 1px solid $color-white;
                    color: $color-white;
                    caret-color: $color-white !important;
                }
                &[type="password"] {
                    padding: 14px 45px 14px 20px;
                    @include max-screen($mobile) {
                        padding: 11px 45px 11px 20px;
                    }
                }

                @include max-screen($mobile) {
                    padding: 11px 20px;
                }
                &::placeholder {
                    color: $color-black;
                    .auth &{
                        color: $color-white;
                    }
                }
                &:disabled {
                    background-color: $color-primary;
                }
                &:-webkit-autofill,
                &:-webkit-autofill:hover,
                &:-webkit-autofill:focus,
                &:-webkit-autofill:active {
                    -webkit-box-shadow: 0 0 0 30px $color-white inset !important;
                    -webkit-text-fill-color: $color-black;
                    .auth &{
                        -webkit-box-shadow: 0 0 0 30px $color-primary inset !important;
                         -webkit-text-fill-color: $color-white;
                    }
                }
            }

            .mat-form-field-label-wrapper {
                .mat-form-field-label {
                    width: fit-content;
                    left: 20px;
                    font-size: 15px;
                    line-height: 24px;
                    top: 28px;
                    color: $color-black;
                    .auth &{
                        color: $color-white;
                    }
                    .pickup &{
                        top: 25px;
                        left: 35px;
                        font-size: 14px;
                        color: $color-black;
                    }
                    .addAddress &{
                        color: $color-black;
                    }
                    @include max-screen($mobile) {
                        font-size: 14px;
                        top: 25px;
                    }

                    .mat-placeholder-required {
                        color: $color-white;
                    }
                }
                .mat-placeholder-required {
                    display: none;
                }
            }
        }
        .mat-form-field-underline {
            display: none;
        }
    }
    &.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
    .mat-form-field-appearance-legacy.mat-form-field-can-float
        .mat-input-server:focus
        + .mat-form-field-label-wrapper
        .mat-form-field-label {
        transform: translateY(-27px) !important;
        padding: 0 4px;
        font-size: 15px;
        line-height: 20px;
        background-color: $color-white;
        width: fit-content !important;
        top: 30px;
        left: 16px;
        color: $color-black;
        .auth &{
            background-color: $color-primary;
            color: $color-white;
        }
        @include max-screen($mobile) {
            font-size: 14px;
        }
    }
    &.ng-invalid.ng-touched {
        .mat-form-field-infix {
            input {
                border-color: $color-error;
            }
        }
    }
    .eyeicon {
        position: absolute;
        width: 24px;
        height: 24px;
        right: 15px;
        top: 15px;
        cursor: pointer;
        @include max-screen($mobile) {
            top: 12px;
        }
    }
    &.mat-form-field-type-mat-select {
        .mat-form-field-flex {
            padding: 0;
            background-color: transparent;
        }
        .mat-select {
            border: 1px solid rgba($color-black, 0.4);
            border-radius: 6px;
            padding: 17px;
            .pickup &{
                padding: 17px 17px 17px 47px; 
                @include max-screen($mobile) {
                    padding: 13px 17px 13px 47px; 
                }
            }
            .mat-select-trigger,
            mat-select-trigger {
                display: inline-flex;
                align-items: center;
                width: 100%;
                .mat-select-value {
                    display: flex;
                    max-width: none;
                    margin-right: 8px;
                    .mat-select-value-text {
                        color: $color-black;
                        text-transform: capitalize;
                    }
                }
                .mat-select-arrow-wrapper {
                    display: inline-flex;
                    opacity: 0.5;
                    .mat-select-arrow {
                        border: 0;
                        @include background-image(dropdown, svg, center, $repeat: no-repeat, $size: contain);
                        width: 14px;
                        height: 7px;
                        .market-status__exchange & {
                            width: 10px;
                        }
                    }
                }
            }
            &[aria-expanded="true"] {
                border-radius: 6px 6px 0 0;
            }
        }
        .mat-form-field-label-wrapper {
            .mat-form-field-label {
                width: -webkit-fit-content;
                width: -moz-fit-content;
                width: fit-content;
                left: 23px;
                font-size: 16px;
                line-height: 24px;
                top: 38px;
            }
        }
        &.mat-form-field-should-float {
            .mat-select {
                border-color: rgba($color-black, 0.4);
            }
            .mat-form-field-label-wrapper {
                .mat-form-field-label {
                    font-size: 12px;
                    padding: 0 4px;
                    transform: translateY(-2.3em);
                    color: $color-secondary;
                    background: $color-white;
                }
            }
        }
    }
    .mat-form-field-prefix {
        position: absolute;
        left: 8px;
        top: 50%;
        height: 20px;
        transform: translateY(-50%);
    }
}
.mat-error, .error {
    color: $color-error;
    font-size: 15px;
    line-height: 1.4;
    font-weight: 600;
    display: block;
    margin-top: 5px;
    a{
        color: $color-error;
        text-decoration: underline;
    }
}

/**check box design**/
.mat-checkbox {
    .mat-checkbox-ripple {
        display: none;
    }
    .mat-checkbox-inner-container {
        margin-top: 0;
    }
    .mat-checkbox-frame {
        content: "";
        width: 20px;
        height: 20px;
        border: 1px solid $color-white;
        border-radius: 4px;
        opacity: 0.4;
        .ordernow & {
            border: 1px solid $color-black;
        }
    }
    .mat-checkbox-background {
        width: 20px;
        height: 20px;
        border-radius: 4px;

        .mat-checkbox-checkmark-path {
            .ordernow & {
                stroke: $color-white !important;
            }
        }
    }
    .mat-checkbox-label {
        line-height: 1 !important;
        cursor: pointer;
        font-size: 14px;
        opacity: 0.81;
        margin-left: 5px;
        margin-top: 4px;
        .ordernow & {
            font-size: 18px;
            margin-top: 2px;
            white-space: normal;
            @include max-screen($mobile) {
                font-size: 13px;
            }
        }
        @include max-screen($mobile) {
            font-size: 13px;
        }
        a {
            color: $color-white !important;
            text-decoration: underline;
            &:hover {
                color: $color-secondary !important;
            }
        }
    }
    &.mat-checkbox-checked.mat-accent {
        .mat-checkbox-background {
            .ordernow & {
                background-color: $color-primary;
            }
        }
    }
}

/**mat expansion panel design**/

.mat-expansion-panel {
    background: $color-white;
    color: $color-black;
    box-shadow: none !important;
    border-radius: 4px !important;
    padding: 23px 40px;
    @include max-screen($tablet) {
        padding: 15px 20px;
    }
 
    &.overflowVisible{
        overflow: visible !important;
    }
    .mat-expansion-panel-header {
        height: auto;
        padding: 0;
        background: $color-white !important;
        &::after {
            content: "";
            @include background-image(plus-circle, svg, 98% center, no-repeat, cover);
            width: 28px;
            height: 28px;
            @include max-screen($tablet) {
                width: 24px;
                height: 24px;
            }
        }
        .mat-expansion-panel-header-title {
            font-size: 20px;
            color: $color-black;
            font-weight: bold;
            text-align: left;
            @include max-screen($tablet){
                font-size: 16px;
            }
        }
        .mat-expansion-indicator {
            display: none;
        }
    }
    .mat-expansion-panel-body {
        padding: 12px 0 0;
        p {
            max-width: 100% !important;
            text-align: left;
            font-size: 18px !important;
            padding: 0 !important;
            color: $color-text !important;
            line-height: 1.5;
            white-space: pre-line;
            @include max-screen($tablet){
                font-size: 14px !important;
            }
        }
    }
    &:not(:last-child) {
        margin: 0 0 20px !important;
    }
    &.mat-expanded {
        .mat-expansion-panel-header {
            &::after {
                content: "";
                @include background-image(minus-circle, svg, 98% center, no-repeat, cover);
            }
        }
    }
}

.cdk-overlay-container{
    z-index: 99999;
}
.cdk-overlay-pane{
    max-height: 90vh;
    max-width: 90vw !important;
    @include max-screen($tablet) {
        width: 100%;
    }
   
}

/*select panel design**/
.mat-select-panel {
    background: $color-white;
    .mat-option {
        color: $color-black;
        &.mat-selected{
            &:not(.mat-option-disabled){
                color: $color-secondary;
            }
        }
       
    }
}