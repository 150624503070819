.slick-current {
    position: relative;
    z-index: 2;
}
.slick-dots {
    li {
        margin: 0 5px;
        button {
            @include round-image(15px);
            background-color: $color-graybg;
            &::before {
                display: none;
            }
        }
        &.slick-active {
            button {
                background-color: $color-secondary;
            }
        }
    }
}
.slick-arrow{
    z-index: 8;   
    &:before{
        font-size: 0;
        width: 20px;
        height: 20px;
        display: block;
    }
    &.slick-next{
        @include background-image(arrow-right, svg, center , no-repeat, 15px);
        right: 0;
        @include max-screen($mobile) {
            @include background-image(arrow-right, svg, center , no-repeat, 10px);
        }
    }
    &.slick-prev{
        @include background-image(arrow-left, svg, center , no-repeat, 15px);
        left: 0;
        @include max-screen($mobile) {
            @include background-image(arrow-left, svg, center , no-repeat, 10px);
        }
    }
    &.slick-disabled{
        opacity: 0.2;
    }
}
.slick-track {
    display: flex;
}
.slick-slide {
    display: flex !important;
    height: auto;
}
