* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: #000;
  font-size: 16px;
  font-family: "GT Walsheim", sans-serif;
  line-height: 1.5;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

a {
  outline: none;
  cursor: pointer;
  text-decoration: none;
}

img {
  height: auto;
  max-width: 100%;
}

:focus {
  outline: none;
}

::-ms-expand {
  display: none;
}

ul,
ol {
  list-style: none;
}

.wrapper {
  max-width: 1500px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  width: 100%;
}
@media only screen and (max-width: 1366px) {
  .wrapper {
    max-width: 1199px;
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(39, 40, 90, 0.2);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(39, 40, 90, 0.8);
  border-radius: 10px;
}

.zopim {
  z-index: 9 !important;
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none;
}

.flex {
  display: flex;
}

.dflex {
  display: flex;
  flex-wrap: wrap;
}

.alignCenter {
  align-items: center;
}

.alignStart {
  align-items: flex-start;
}

.alignEnd {
  align-items: flex-end;
}

.justifySpaceBetween {
  justify-content: space-between;
}

.justifyCenter {
  justify-content: center;
}

.justifyEnd {
  justify-content: flex-end;
}

.flexCol {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.auth {
  min-height: 100vh;
  position: relative;
  background-color: #2e3060;
}
@media only screen and (max-width: 991px) {
  .auth {
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
}
.auth__left, .auth__right {
  width: 50%;
}
@media only screen and (max-width: 991px) {
  .auth__left, .auth__right {
    width: 100%;
  }
}
.auth__left {
  padding: 60px 20px;
  background-color: #2e3060;
}
@media only screen and (max-width: 991px) {
  .auth__left {
    padding: 30px 20px;
    flex: 1;
  }
}
@media only screen and (max-width: 767px) {
  .auth__left {
    padding: 30px 20px 60px;
  }
}
.auth__left-inner {
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
}
.auth__left-logo {
  width: 100%;
  max-width: 500px;
  margin: 0 auto 40px;
}
@media only screen and (max-width: 991px) {
  .auth__left-logo {
    position: absolute;
    top: 20px;
    left: 0px;
    padding-left: 20px;
    max-width: 100%;
  }
}
.auth__left-content {
  flex: 1;
}
.auth__right {
  background-image: url(/assets/images/clock.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 991px) {
  .auth__right {
    min-height: 300px;
    flex: 1;
  }
}

.authform {
  color: #fff;
  width: 100%;
}
.authform h3 {
  font-size: 35px;
  margin-bottom: 28px;
  font-weight: 600;
}
@media only screen and (max-width: 991px) {
  .authform h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
}
.authform__field {
  margin-top: 25px;
}
.authform__text {
  text-align: right;
  padding: 10px 0 0;
}
.authform__text a {
  font-size: 16px;
  color: #fddf60;
}
.authform__text a:hover {
  color: #eac93d;
}
.authform__btn {
  padding: 30px 0;
}
@media only screen and (max-width: 991px) {
  .authform__btn {
    padding: 20px 0;
  }
}
.authform__bottom {
  text-align: center;
}
.authform__bottom a {
  color: #fff;
}
.authform__bottom a:hover {
  color: #eac93d;
}

app-login,
app-sign-up,
app-recover-password,
app-new-password {
  width: 100%;
}

.contentpage {
  padding: 40px 0;
}
@media only screen and (max-width: 1024px) {
  .contentpage {
    padding: 20px 0;
  }
}
.contentpage h1 {
  color: #000;
  margin-bottom: 20px;
  font-size: 40px;
}
@media only screen and (max-width: 1024px) {
  .contentpage h1 {
    font-size: 25px;
    margin-bottom: 10px;
  }
}
.contentpage h3 {
  font-size: 20px;
  margin-bottom: 15px;
  color: #000;
}
@media only screen and (max-width: 1024px) {
  .contentpage h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
.contentpage p {
  color: #57595c;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1024px) {
  .contentpage p {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 15px;
  }
}
.contentpage p:last-child {
  margin-bottom: 0;
}
.contentpage a {
  color: #27285a;
}
.contentpage a:hover {
  text-decoration: underline;
}

.mainRow {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.mainRow__col {
  width: calc(50% - 15px);
  margin-right: 15px;
}
@media only screen and (max-width: 767px) {
  .mainRow__col {
    width: 100%;
    margin: 0;
  }
}
.mainRow__col:last-child {
  margin-left: 15px;
  margin-right: 0;
}
@media only screen and (max-width: 767px) {
  .mainRow__col:last-child {
    width: 100%;
    margin: 0;
  }
}

.order__form-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
@media only screen and (max-width: 767px) {
  .order__form-row {
    margin: 0;
  }
}
.order__form-col {
  width: 50%;
  padding: 0 10px;
  margin-top: 15px;
}
@media only screen and (max-width: 767px) {
  .order__form-col {
    width: 100%;
    padding: 0;
  }
}
.order__form-half {
  width: 25%;
  padding: 0 10px;
  margin-top: 15px;
}
@media only screen and (max-width: 1366px) {
  .order__form-half {
    width: 50%;
  }
}
@media only screen and (max-width: 767px) {
  .order__form-half {
    width: 100%;
    padding: 0;
  }
}

/**mat font override**/
.mat-expansion-panel-header,
.mat-expansion-panel-content,
.mat-form-field,
.mat-checkbox,
.mat-snack-bar-container,
textarea {
  font-family: "GT Walsheim", sans-serif !important;
}

/**Snackbar design override**/
.mat-snack-bar-container {
  padding: 0 !important;
  min-height: 0 !important;
  background-color: transparent !important;
  margin: 0 auto 20px !important;
}
@media only screen and (max-width: 767px) {
  .mat-snack-bar-container {
    margin: 20px auto !important;
  }
}

/**matt field override**/
.mat-form-field {
  width: 100%;
}
.mat-form-field .mat-form-field-wrapper {
  padding: 0 !important;
  width: 100%;
}
.mat-form-field .mat-form-field-wrapper .mat-form-field-flex {
  align-items: center;
}
.mat-form-field .mat-form-field-wrapper .mat-form-field-infix {
  margin: 0;
  padding: 0;
  border: 0;
}
.mat-form-field .mat-form-field-wrapper .mat-form-field-infix input {
  font-size: 15px;
  line-height: 24px;
  border-radius: 4px;
  padding: 14px 20px;
  box-sizing: border-box;
  border: 1px solid #000;
  color: #000;
  font-weight: 400;
  caret-color: #000 !important;
  margin-top: 0;
}
.auth .mat-form-field .mat-form-field-wrapper .mat-form-field-infix input {
  border: 1px solid #fff;
  color: #fff;
  caret-color: #fff !important;
}
.mat-form-field .mat-form-field-wrapper .mat-form-field-infix input[type=password] {
  padding: 14px 45px 14px 20px;
}
@media only screen and (max-width: 767px) {
  .mat-form-field .mat-form-field-wrapper .mat-form-field-infix input[type=password] {
    padding: 11px 45px 11px 20px;
  }
}
@media only screen and (max-width: 767px) {
  .mat-form-field .mat-form-field-wrapper .mat-form-field-infix input {
    padding: 11px 20px;
  }
}
.mat-form-field .mat-form-field-wrapper .mat-form-field-infix input::placeholder {
  color: #000;
}
.auth .mat-form-field .mat-form-field-wrapper .mat-form-field-infix input::placeholder {
  color: #fff;
}
.mat-form-field .mat-form-field-wrapper .mat-form-field-infix input:disabled {
  background-color: #2e3060;
}
.mat-form-field .mat-form-field-wrapper .mat-form-field-infix input:-webkit-autofill, .mat-form-field .mat-form-field-wrapper .mat-form-field-infix input:-webkit-autofill:hover, .mat-form-field .mat-form-field-wrapper .mat-form-field-infix input:-webkit-autofill:focus, .mat-form-field .mat-form-field-wrapper .mat-form-field-infix input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  -webkit-text-fill-color: #000;
}
.auth .mat-form-field .mat-form-field-wrapper .mat-form-field-infix input:-webkit-autofill, .auth .mat-form-field .mat-form-field-wrapper .mat-form-field-infix input:-webkit-autofill:hover, .auth .mat-form-field .mat-form-field-wrapper .mat-form-field-infix input:-webkit-autofill:focus, .auth .mat-form-field .mat-form-field-wrapper .mat-form-field-infix input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #2e3060 inset !important;
  -webkit-text-fill-color: #fff;
}
.mat-form-field .mat-form-field-wrapper .mat-form-field-infix .mat-form-field-label-wrapper .mat-form-field-label {
  width: fit-content;
  left: 20px;
  font-size: 15px;
  line-height: 24px;
  top: 28px;
  color: #000;
}
.auth .mat-form-field .mat-form-field-wrapper .mat-form-field-infix .mat-form-field-label-wrapper .mat-form-field-label {
  color: #fff;
}
.pickup .mat-form-field .mat-form-field-wrapper .mat-form-field-infix .mat-form-field-label-wrapper .mat-form-field-label {
  top: 25px;
  left: 35px;
  font-size: 14px;
  color: #000;
}
.addAddress .mat-form-field .mat-form-field-wrapper .mat-form-field-infix .mat-form-field-label-wrapper .mat-form-field-label {
  color: #000;
}
@media only screen and (max-width: 767px) {
  .mat-form-field .mat-form-field-wrapper .mat-form-field-infix .mat-form-field-label-wrapper .mat-form-field-label {
    font-size: 14px;
    top: 25px;
  }
}
.mat-form-field .mat-form-field-wrapper .mat-form-field-infix .mat-form-field-label-wrapper .mat-form-field-label .mat-placeholder-required {
  color: #fff;
}
.mat-form-field .mat-form-field-wrapper .mat-form-field-infix .mat-form-field-label-wrapper .mat-placeholder-required {
  display: none;
}
.mat-form-field .mat-form-field-wrapper .mat-form-field-underline {
  display: none;
}
.mat-form-field.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-27px) !important;
  padding: 0 4px;
  font-size: 15px;
  line-height: 20px;
  background-color: #fff;
  width: fit-content !important;
  top: 30px;
  left: 16px;
  color: #000;
}
.auth .mat-form-field.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.auth .mat-form-field .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  background-color: #2e3060;
  color: #fff;
}
@media only screen and (max-width: 767px) {
  .mat-form-field.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    font-size: 14px;
  }
}
.mat-form-field.ng-invalid.ng-touched .mat-form-field-infix input {
  border-color: red;
}
.mat-form-field .eyeicon {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 15px;
  top: 15px;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .mat-form-field .eyeicon {
    top: 12px;
  }
}
.mat-form-field.mat-form-field-type-mat-select .mat-form-field-flex {
  padding: 0;
  background-color: transparent;
}
.mat-form-field.mat-form-field-type-mat-select .mat-select {
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  padding: 17px;
}
.pickup .mat-form-field.mat-form-field-type-mat-select .mat-select {
  padding: 17px 17px 17px 47px;
}
@media only screen and (max-width: 767px) {
  .pickup .mat-form-field.mat-form-field-type-mat-select .mat-select {
    padding: 13px 17px 13px 47px;
  }
}
.mat-form-field.mat-form-field-type-mat-select .mat-select .mat-select-trigger,
.mat-form-field.mat-form-field-type-mat-select .mat-select mat-select-trigger {
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.mat-form-field.mat-form-field-type-mat-select .mat-select .mat-select-trigger .mat-select-value,
.mat-form-field.mat-form-field-type-mat-select .mat-select mat-select-trigger .mat-select-value {
  display: flex;
  max-width: none;
  margin-right: 8px;
}
.mat-form-field.mat-form-field-type-mat-select .mat-select .mat-select-trigger .mat-select-value .mat-select-value-text,
.mat-form-field.mat-form-field-type-mat-select .mat-select mat-select-trigger .mat-select-value .mat-select-value-text {
  color: #000;
  text-transform: capitalize;
}
.mat-form-field.mat-form-field-type-mat-select .mat-select .mat-select-trigger .mat-select-arrow-wrapper,
.mat-form-field.mat-form-field-type-mat-select .mat-select mat-select-trigger .mat-select-arrow-wrapper {
  display: inline-flex;
  opacity: 0.5;
}
.mat-form-field.mat-form-field-type-mat-select .mat-select .mat-select-trigger .mat-select-arrow-wrapper .mat-select-arrow,
.mat-form-field.mat-form-field-type-mat-select .mat-select mat-select-trigger .mat-select-arrow-wrapper .mat-select-arrow {
  border: 0;
  background-image: url(/assets/images/dropdown.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 14px;
  height: 7px;
}
.market-status__exchange .mat-form-field.mat-form-field-type-mat-select .mat-select .mat-select-trigger .mat-select-arrow-wrapper .mat-select-arrow,
.market-status__exchange .mat-form-field.mat-form-field-type-mat-select .mat-select mat-select-trigger .mat-select-arrow-wrapper .mat-select-arrow {
  width: 10px;
}
.mat-form-field.mat-form-field-type-mat-select .mat-select[aria-expanded=true] {
  border-radius: 6px 6px 0 0;
}
.mat-form-field.mat-form-field-type-mat-select .mat-form-field-label-wrapper .mat-form-field-label {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  left: 23px;
  font-size: 16px;
  line-height: 24px;
  top: 38px;
}
.mat-form-field.mat-form-field-type-mat-select.mat-form-field-should-float .mat-select {
  border-color: rgba(0, 0, 0, 0.4);
}
.mat-form-field.mat-form-field-type-mat-select.mat-form-field-should-float .mat-form-field-label-wrapper .mat-form-field-label {
  font-size: 12px;
  padding: 0 4px;
  transform: translateY(-2.3em);
  color: #fddf60;
  background: #fff;
}
.mat-form-field .mat-form-field-prefix {
  position: absolute;
  left: 8px;
  top: 50%;
  height: 20px;
  transform: translateY(-50%);
}

.mat-error, .error {
  color: red;
  font-size: 15px;
  line-height: 1.4;
  font-weight: 600;
  display: block;
  margin-top: 5px;
}
.mat-error a, .error a {
  color: red;
  text-decoration: underline;
}

/**check box design**/
.mat-checkbox .mat-checkbox-ripple {
  display: none;
}
.mat-checkbox .mat-checkbox-inner-container {
  margin-top: 0;
}
.mat-checkbox .mat-checkbox-frame {
  content: "";
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
  border-radius: 4px;
  opacity: 0.4;
}
.ordernow .mat-checkbox .mat-checkbox-frame {
  border: 1px solid #000;
}
.mat-checkbox .mat-checkbox-background {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}
.ordernow .mat-checkbox .mat-checkbox-background .mat-checkbox-checkmark-path {
  stroke: #fff !important;
}
.mat-checkbox .mat-checkbox-label {
  line-height: 1 !important;
  cursor: pointer;
  font-size: 14px;
  opacity: 0.81;
  margin-left: 5px;
  margin-top: 4px;
}
.ordernow .mat-checkbox .mat-checkbox-label {
  font-size: 18px;
  margin-top: 2px;
  white-space: normal;
}
@media only screen and (max-width: 767px) {
  .ordernow .mat-checkbox .mat-checkbox-label {
    font-size: 13px;
  }
}
@media only screen and (max-width: 767px) {
  .mat-checkbox .mat-checkbox-label {
    font-size: 13px;
  }
}
.mat-checkbox .mat-checkbox-label a {
  color: #fff !important;
  text-decoration: underline;
}
.mat-checkbox .mat-checkbox-label a:hover {
  color: #fddf60 !important;
}
.ordernow .mat-checkbox.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #2e3060;
}

/**mat expansion panel design**/
.mat-expansion-panel {
  background: #fff;
  color: #000;
  box-shadow: none !important;
  border-radius: 4px !important;
  padding: 23px 40px;
}
@media only screen and (max-width: 1024px) {
  .mat-expansion-panel {
    padding: 15px 20px;
  }
}
.mat-expansion-panel.overflowVisible {
  overflow: visible !important;
}
.mat-expansion-panel .mat-expansion-panel-header {
  height: auto;
  padding: 0;
  background: #fff !important;
}
.mat-expansion-panel .mat-expansion-panel-header::after {
  content: "";
  background-image: url(/assets/images/plus-circle.svg);
  background-size: cover;
  background-position: 98% center;
  background-repeat: no-repeat;
  width: 28px;
  height: 28px;
}
@media only screen and (max-width: 1024px) {
  .mat-expansion-panel .mat-expansion-panel-header::after {
    width: 24px;
    height: 24px;
  }
}
.mat-expansion-panel .mat-expansion-panel-header .mat-expansion-panel-header-title {
  font-size: 20px;
  color: #000;
  font-weight: bold;
  text-align: left;
}
@media only screen and (max-width: 1024px) {
  .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-panel-header-title {
    font-size: 16px;
  }
}
.mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator {
  display: none;
}
.mat-expansion-panel .mat-expansion-panel-body {
  padding: 12px 0 0;
}
.mat-expansion-panel .mat-expansion-panel-body p {
  max-width: 100% !important;
  text-align: left;
  font-size: 18px !important;
  padding: 0 !important;
  color: #3a4863 !important;
  line-height: 1.5;
  white-space: pre-line;
}
@media only screen and (max-width: 1024px) {
  .mat-expansion-panel .mat-expansion-panel-body p {
    font-size: 14px !important;
  }
}
.mat-expansion-panel:not(:last-child) {
  margin: 0 0 20px !important;
}
.mat-expansion-panel.mat-expanded .mat-expansion-panel-header::after {
  content: "";
  background-image: url(/assets/images/minus-circle.svg);
  background-size: cover;
  background-position: 98% center;
  background-repeat: no-repeat;
}

.cdk-overlay-container {
  z-index: 99999;
}

.cdk-overlay-pane {
  max-height: 90vh;
  max-width: 90vw !important;
}
@media only screen and (max-width: 1024px) {
  .cdk-overlay-pane {
    width: 100%;
  }
}

/*select panel design**/
.mat-select-panel {
  background: #fff;
}
.mat-select-panel .mat-option {
  color: #000;
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #fddf60;
}

.slick-current {
  position: relative;
  z-index: 2;
}

.slick-dots li {
  margin: 0 5px;
}
.slick-dots li button {
  width: 15px;
  height: 15px;
  min-width: 15px;
  object-fit: cover;
  border-radius: 50%;
  background-color: #f8f8f8;
}
.slick-dots li button::before {
  display: none;
}
.slick-dots li.slick-active button {
  background-color: #fddf60;
}

.slick-arrow {
  z-index: 8;
}
.slick-arrow:before {
  font-size: 0;
  width: 20px;
  height: 20px;
  display: block;
}
.slick-arrow.slick-next {
  background-image: url(/assets/images/arrow-right.svg);
  background-size: 15px;
  background-position: center;
  background-repeat: no-repeat;
  right: 0;
}
@media only screen and (max-width: 767px) {
  .slick-arrow.slick-next {
    background-image: url(/assets/images/arrow-right.svg);
    background-size: 10px;
    background-position: center;
    background-repeat: no-repeat;
  }
}
.slick-arrow.slick-prev {
  background-image: url(/assets/images/arrow-left.svg);
  background-size: 15px;
  background-position: center;
  background-repeat: no-repeat;
  left: 0;
}
@media only screen and (max-width: 767px) {
  .slick-arrow.slick-prev {
    background-image: url(/assets/images/arrow-left.svg);
    background-size: 10px;
    background-position: center;
    background-repeat: no-repeat;
  }
}
.slick-arrow.slick-disabled {
  opacity: 0.2;
}

.slick-track {
  display: flex;
}

.slick-slide {
  display: flex !important;
  height: auto;
}

.mat-dialog-container {
  background-color: #fff;
  color: #000;
  position: relative;
  padding: 40px !important;
  border-radius: 10px !important;
  width: 850px !important;
}
@media only screen and (max-width: 1024px) {
  .mat-dialog-container {
    padding: 20px !important;
    width: 100% !important;
  }
}
.mat-dialog-container .modal header {
  margin-bottom: 15px;
}
@media only screen and (max-width: 1024px) {
  .mat-dialog-container .modal header {
    margin-bottom: 15px;
  }
}
.mat-dialog-container .modal header.gray {
  background-color: #f3f5f9;
  padding: 25px 40px;
  margin: -40px -40px 20px;
  position: sticky;
  top: -40px;
}
@media only screen and (max-width: 1024px) {
  .mat-dialog-container .modal header.gray {
    padding: 20px;
    margin: -20px -20px 20px;
    top: -20px;
  }
}
.mat-dialog-container .modal header h2 {
  font-size: 28px;
}
@media only screen and (max-width: 1024px) {
  .mat-dialog-container .modal header h2 {
    font-size: 20px;
  }
}
.mat-dialog-container .modal__buttonCol {
  margin: 25px -15px 0;
}
.mat-dialog-container .modal__buttonCol app-button {
  width: 50%;
  display: block;
  padding: 0 15px;
}
@media only screen and (max-width: 575px) {
  .mat-dialog-container .modal__buttonCol app-button {
    width: 100%;
    margin-bottom: 15px;
  }
}
.mat-dialog-container .modal__button {
  margin: 25px 0 0;
}
.mat-dialog-container .modal__button app-button {
  width: 100%;
  display: block;
}
.mat-dialog-container .modal__close {
  background-color: #f3f5f9;
  width: 38px;
  height: 38px;
  min-width: 38px;
  object-fit: cover;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .mat-dialog-container .modal__close {
    width: 20px;
    height: 20px;
    min-width: 20px;
    object-fit: cover;
    border-radius: 50%;
  }
}
.mat-dialog-container .modal__close:hover {
  background-color: #f6f8fb;
}
.mat-dialog-container .modal--center {
  text-align: center;
}

@font-face {
  font-family: "GT Walsheim";
  src: url("../fonts/GTWalsheim-Rg.eot");
  src: url("../fonts/GTWalsheim-Rg.eot?#iefix") format("embedded-opentype"), url("../fonts/GTWalsheim-Rg.woff2") format("woff2"), url("../fonts/GTWalsheim-Rg.woff") format("woff"), url("../fonts/GTWalsheim-Rg.ttf") format("truetype"), url("../fonts/GTWalsheim-Rg.svg#GTWalsheim-Rg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "GT Walsheim";
  src: url("../fonts/GTWalsheim-RgOb.eot");
  src: url("../fonts/GTWalsheim-RgOb.eot?#iefix") format("embedded-opentype"), url("../fonts/GTWalsheim-RgOb.woff2") format("woff2"), url("../fonts/GTWalsheim-RgOb.woff") format("woff"), url("../fonts/GTWalsheim-RgOb.ttf") format("truetype"), url("../fonts/GTWalsheim-RgOb.svg#GTWalsheim-RgOb") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "GT Walsheim";
  src: url("../fonts/GTWalsheim-Bold.eot");
  src: url("../fonts/GTWalsheim-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/GTWalsheim-Bold.woff2") format("woff2"), url("../fonts/GTWalsheim-Bold.woff") format("woff"), url("../fonts/GTWalsheim-Bold.ttf") format("truetype"), url("../fonts/GTWalsheim-Bold.svg#GTWalsheim-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "GT Walsheim";
  src: url("../fonts/GTWalsheim-BoldOblique.eot");
  src: url("../fonts/GTWalsheim-BoldOblique.eot?#iefix") format("embedded-opentype"), url("../fonts/GTWalsheim-BoldOblique.woff2") format("woff2"), url("../fonts/GTWalsheim-BoldOblique.woff") format("woff"), url("../fonts/GTWalsheim-BoldOblique.ttf") format("truetype"), url("../fonts/GTWalsheim-BoldOblique.svg#GTWalsheim-BoldOblique") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "GT Walsheim";
  src: url("../fonts/GTWalsheim-Light.eot");
  src: url("../fonts/GTWalsheim-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/GTWalsheim-Light.woff2") format("woff2"), url("../fonts/GTWalsheim-Light.woff") format("woff"), url("../fonts/GTWalsheim-Light.ttf") format("truetype"), url("../fonts/GTWalsheim-Light.svg#GTWalsheim-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "GT Walsheim";
  src: url("../fonts/GTWalsheim-LightOblique.eot");
  src: url("../fonts/GTWalsheim-LightOblique.eot?#iefix") format("embedded-opentype"), url("../fonts/GTWalsheim-LightOblique.woff2") format("woff2"), url("../fonts/GTWalsheim-LightOblique.woff") format("woff"), url("../fonts/GTWalsheim-LightOblique.ttf") format("truetype"), url("../fonts/GTWalsheim-LightOblique.svg#GTWalsheim-LightOblique") format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}