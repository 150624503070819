// colors
$color-black: #000;
$color-white: #fff;
$color-primary: #2e3060;
$color-primarydark: #27285a;
$color-secondary: #fddf60;
$color-secondarydark: #eac93d;
$color-dark:#100205;
$color-grey:#ccc;
$color-graybg: #f8f8f8;
$color-darkgrey: #57595c;
$color-text: #3a4863;
$color-success: #2ac19d;
$color-disable: #d9c77b;
$color-graydarkbg: #F0F1F4;
$color-graylightbg: #e3e3e3;
$color-lightblue: #f3f5f9;
$color-error: red;
$color-errorbg: #aa3034;
$color-greendark: #39c87b;
$color-bggreen: #abf0e0;
$color-btntext: #1c295d;
$color-errorbgLight: #ffe8e9;

// base
$font-size-base: 16px;
$line-height-base: 1.5;
$font-family-base: "GT Walsheim", sans-serif;
$radius: 10px;
$shadow: 0px 1px 16px rgb(0 0 0 / 10%);
$shadow2: 0px 5px 28px rgb(8 15 52 / 4%);
$transition: 0.3s ease-in-out;

// link default style
$link-color: $color-primary;
$link-hover-color: $color-btntext;

// container width
$max-width: 1500px;

// grid
$gutter-side: 20px;

// breakpoints
$smobile: 575px;
$mobile: 767px;
$stablet: 991px;
$tablet: 1024px;
$desktop: 1199px;
$desktop-large: 1366px;
$desktop-xlarge: 1599px;

// image path
$imgPath: "/assets/images/";

// mixins
@import "mixins";